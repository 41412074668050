<template>
	<form class="form-horizontal" @submit.prevent="save">
		<div class="animated fadeIn">
			<b-card class="min-vh-100">
				<div class="display-table full-width mb-2">
					<strong class="card-title">{{$route.meta.label}}</strong>
				</div>
				<b-row>
					<ResponseAlert ref="response" />
					<b-col md="3" class="mb-2">
						<div class="image-input">
							<b-img :src="getUrlOfFile(models.thumbnail)" class="mb-2" />
							<b-button class="btn-upload btn-block" @click="$refs.filemanager.show('thumbnail')">
									<i class="fa fa-upload"></i> Upload Thumbnail
							</b-button>
						</div>
					</b-col>
					<b-col md="6">
						<div v-if="models.pdf" class="text-center mb-3">
							<a :href="models.pdf" target="_blank" class="d-block mb-2">
								<img src="/img/pdf-icon.png" alt="pdf icon" width="100">
							</a>

							<span>{{vars.filename}}</span>
						</div>
						<b-button block variant="outline-primary mb-3" @click="$refs.filemanager.show('pdf')">
							<i class="fa fa-upload"></i> Upload PDF
						</b-button>
						<b-form-group v-bind:class="{ 'is-invalid': errors.has('title')}" label="Title">
							<b-form-input v-model="models.title" v-validate="'required'" data-vv-name="title">
							</b-form-input>
							<div v-if="errors.has('title')">
								<p class="text-danger">{{ errors.first('title') }}</p>
							</div>
						</b-form-group>
						<div id="datepicker-date-wrapper">
							<label for="datepicker-date">Date</label>
							<input class="d-block w-100 mb-3 p-2" style="border: 1px solid #e8e8e8;" type="datetime-local" id="date" name="date" v-model="models.date">
						</div>
						<div id="datepicker-date-wrapper">
							<label for="datepicker-date">Publish At</label>
							<input class="d-block w-100 mb-3 p-2" style="border: 1px solid #e8e8e8;" type="datetime-local" id="publishAt" name="publishAt" v-model="models.publish_at">
						</div>
						<b-form-group>
							<b-form-radio-group
								v-model="models.status"
								name="status"
								class="my-3"
							>
								<b-form-radio value="publish">Publish</b-form-radio>
								<b-form-radio value="draft">Draft</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
				<template #footer>
					<b-button type="submit" variant="primary" class="btn-min-width rounded float-left">
						Save
					</b-button>
					<b-button type="button" variant="light" class="btn-min-width rounded float-left" @click="$router.push({name: config.uri})">
						Cancel
					</b-button>
				</template>
			</b-card>
		</div>
		<Filemanager ref="filemanager" name="categories" @selectMedia="selectMedia"/>
	</form>
</template>
<script>
	import moment from 'moment'
	import api from '@/utils/api.js'
	import ResponseAlert from '@/components/response_alert';
	import Filemanager from '@/components/file_manager';
	import {
		getUrlOfFile,
		getFilenameFromUrl
	} from '@/utils/helpers.js';

	export default {
		name: 'FormEpaper',
		components: {
			ResponseAlert,
			Filemanager,
		},
		data() {
			return {
				config: {
					uri: this.$route.meta.permission.link,
				},
				models: {
					title: '',
					date: '',
					thumbnail: '',
					pdf: '',
					status: 'publish',
                    publish_at: '',
				},
				vars: {
					filename: ''
				},
				masters: {
					parents: [],
				},
				moment
			}
		},
		created() {
			let _ = this
			_.getById();
		},
		methods: {
			getById() {
				const _ = this;
				if (_.$route.params.id) {
					_.$axios.get(`${api.epaper}/${_.$route.params.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.title = data.title
							_.models.date = moment(data.date).format('YYYY-MM-DDTHH:mm')
							_.models.thumbnail = data.thumbnail || ''
							_.models.pdf = data.pdf
							_.models.status = data.status
                            _.models.publish_at = data.publish_at

							_.vars.filename = getFilenameFromUrl(data.pdf)
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
			selectMedia(field, media) {
				const _ = this;
				const imageExtension = ['jpg', 'jpeg', 'png']
				const fileExtension = ['pdf']
				const isExtensionMatch = extensions => extensions.indexOf(media.file.substring(media.file.lastIndexOf(".") + 1)) > -1

				if(field === 'thumbnail' && !isExtensionMatch(imageExtension)){
					return alert('Only accept image file')
				} else if (field === 'pdf' && !isExtensionMatch(fileExtension)) {
					return alert('Only accept document file')
				}
				_.models[field] = media.file
				_.vars.filename = media.title
			},
			getUrlOfFile(file) {
				return getUrlOfFile(file, true);
			},
			
			save() {
				const _ = this;
				_.$validator.validate().then(valid => {
					if (valid) {
						let request = "";
						let message = "create"
						if (!_.$route.params.id) {
							request = _.$axios.post(api.epaper, _.models);
						} else {
							message = "update"
							request = _.$axios.put(`${api.epaper}/${_.$route.params.id}`, _.models);
						}
						request
							.then(() => {
								this.$showToast('Success', `Success ${message} e-paper`)
								_.$router.push({
									name: _.config.uri
								})
							})
							.catch(error => _.$refs.response.setResponse(error.response.data.message, 'danger'));
					} else {
						window.scrollTo(0, 0);
					}
				});
			}
		}
	}
</script>